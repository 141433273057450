import React, { useContext, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Context
import { HeaderContext } from 'context/header';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';
import Contact from 'components/contactUs/contact';
import HealthInsurance from 'components/HealthInsurance';
import Menu from 'components/contactUs/menu';
import { CarouselBanner } from 'components/CmsComponents/CarouselBanner';

// Styles
const Content = styled.div`
  @media ${device.laptop} {
    padding: 0 1.25rem;
  }
`;

const WrapMenu = styled.div`
  width: 100%;
  max-width: 35.625rem;
  margin: 0 auto;

  @media ${device.laptop} {
    max-width: unset;
    padding: 0 1.25rem;
  }
`;

const Box = styled.section`
  width: 100%;
  max-width: 73.125rem;
  margin: 4.375rem auto;

  @media ${device.laptop} {
    margin: 1.25rem auto;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: normal;
  color: #231f20;
  margin-bottom: 4.375rem;

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin-bottom: 1.875rem;
  }
`;

const WrapHealthInsurance = styled.div`
  margin-bottom: 4.375rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  if (page.length > 0) {
    const { fragments } = page[0];
    const findFragment = fragments.find(item => item.id === fragmentId);

    if (isMarkdown) {
      const { markdown } = flatten(findFragment.localizations)[0];
      return markdown;
    } else {
      return flatten(findFragment.localizations);
    }
  }
};

const Agendamento = ({ page, location }) => {
  const {
    setHasDefaultHeader,
    setCallCenterBackLink,
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  const pathname = location.pathname;
  const carouselBannerContent = page[0]?.carouselBanner;

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setHasDefaultHeader(false);
      setCallCenterBackLink('/fale-conosco/');
    }
  }, []);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  // ====== WEBCHAT ====== //
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.type = 'text/javascript';
  //   script.src =
  //     'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705/assets/js/bootstrap.min.js';
  //   document.body.appendChild(script);

  //   const handleScriptLoad = () => {
  //     try {
  //       window.embeddedservice_bootstrap.settings.language = 'pt_BR';
  //       window.embeddedservice_bootstrap.init(
  //         '00D41000000exwd',
  //         'Webchat_Agendamento_Sites',
  //         'https://hospitalsiriolibanes.my.site.com/ESWWebchatAgendamentoSi1726091868705',
  //         {
  //           scrt2URL: 'https://hospitalsiriolibanes.my.salesforce-scrt.com',
  //         }
  //       );
  //     } catch (err) {
  //       console.error('Error loading Embedded Messaging: ', err);
  //     }
  //   };

  //   script.addEventListener('load', handleScriptLoad);

  //   return () => {
  //     script.removeEventListener('load', handleScriptLoad);
  //     document.body.removeChild(script);

  //     [
  //       'embedded-messaging',
  //       'embeddedMessagingSiteContextFrame',
  //       'embeddedMessagingFilePreviewFrame',
  //     ].forEach(id => {
  //       const element = document.getElementById(id);
  //       if (element) {
  //         document.body.removeChild(element);
  //       }
  //     });
  //   };
  // }, []);

  return (
    <>
      <Layout>
        <SEO dataSeo={page} />
        {carouselBannerContent && (
          <CarouselBanner content={carouselBannerContent} />
        )}
        <Content>
          <Box>
            <Title>Agendamento de consultas e exames por telefone</Title>
            <Contact
              markdown={getMarkdown(page, 'cl0lcctlks4rk0eljkc7qsd6o', true)}
              schedulingConsultationsExams
            />
            <Contact
              markdown={getMarkdown(page, 'ckihsdsr404420a267rcq94fh', true)}
              schedulingConsultationsExams
            />
            <Contact
              markdown={getMarkdown(page, 'ckihjzmo006g10a2210r75t8u', true)}
              schedulingConsultationsExams
            />
            <Contact
              markdown={getMarkdown(page, 'cki7h155c3q1p0b71q4m6ez1n', true)}
              schedulingConsultationsExams
              schedulingConsultationsExamsMaxWidth="unset"
            />
          </Box>
        </Content>
        <WrapHealthInsurance>
          <HealthInsurance />
        </WrapHealthInsurance>
        <WrapMenu>
          <Menu intern pathname={pathname} />
        </WrapMenu>
      </Layout>
    </>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cki7hdg1s3q5d0a20qfrcpzj3" }) {
                carouselBanner(locales: [pt_BR, en]) {
                  id
                  name
                  activeBreaknews
                  breaknews {
                    id
                    name
                    type
                    singletexts
                    markdown
                  }
                  bannersData {
                    id
                    buttonLink
                    buttonText
                    backgroundColor {
                      hex
                    }
                    bannerImage {
                      url
                    }
                    bannerImageMobile {
                      url
                    }
                    subtitle
                    title
                    imageLink
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <Agendamento location={location} page={response.gcms.site.pages} />
        );
      }}
    />
  );
};
